import { checkObjectType } from '../utils/FrontendTypeCheckers';
/* eslint-disable no-unused-vars */
class WSClient {
  constructor(url, onErrorFun, onReceivedMessageFun) {
    const { hostname, port, protocol } = window.location;
    const domain = hostname;

    const WSProtocol = protocol === 'https:' ? 'wss:' : 'ws:';
    this.url = WSProtocol + domain + ':' + port + url;
    this.onErrorFun = onErrorFun; // param: { WebSocket.ErrorEvent}
    this.onReceivedMessageFun = onReceivedMessageFun; // param: { string }
    this.websocket = null;
  }

  connect() {
    this.websocket = new WebSocket(this.url);
    /**
            onopen: ((event: WebSocket.Event) => void) | null;
            onerror: ((event: WebSocket.ErrorEvent) => void) | null;
            onclose: ((event: WebSocket.CloseEvent) => void) | null;
            onmessage: ((event: WebSocket.MessageEvent) => void) | null;
         */
    this.websocket.onopen = this.onOpen.bind(this);
    this.websocket.onerror = this.onError.bind(this);
    this.websocket.onmessage = this.onMessage.bind(this);
    this.websocket.onclose = this.onClose.bind(this);
  }
  disconnect() {
    if (this.websocket) {
      this.websocket.close();
    }
  }

  isConnected() {
    return this.websocket && this.websocket.readyState === WebSocket.OPEN;
  }

  onError(event) {
    console.error('WebSocket error observed:', event);
    this.onErrorFun(event);
  }

  onOpen(event) {
    console.log('WebSocket connection established.');
  }

  onClose(event) {
    console.log('WebSocket connection closed.');
  }

  onMessage(event) {
    const message = event.data;
    this.onReceivedMessageFun(message);
  }

  /**
   *
   * @param {*} msgObj
   * @param {bool} isReConnected avoid repeatedly reconnecting.
   * @returns
   */
  sendMessage(msgObj, isReConnected = false) {
    checkObjectType(msgObj, 'msgObj');
    if (this.websocket && this.isConnected()) {
      const msg = JSON.stringify(msgObj);
      this.websocket.send(msg);
      console.log('WebSocket sent message#');
      return true;
    } else if (!isReConnected) {
      console.error('WebSocket is not connected. ');
      return false;
    } else {
      console.error('WebSocket is not connected. msg lost.');
    }
    return false;
  }
}

export default WSClient;

// 示例用法:
// const url = 'wss://example.com/ws';
// const onError = (error) => {
//     console.error('WebSocket error:', error);
// };

// const onMessage = (event) => {
//     console.log('WebSocket received message:', event.data);
// };

// const websocketClient = new WebSocketClient(url, onError, onMessage);
// websocketClient.connect();

// // 发送消息示例
// websocketClient.sendMessage('Hello, WebSocket!');
