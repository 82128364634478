// all these function below will throw an error if the type is not expected.

export function checkNonEmptyArray(array) {
  if (Array.isArray(array) && array.length > 0) return;

  throw new Error('array is empty  or not a expected type.');
}

export function checkNonEmptyString(str) {
  if (typeof str === 'string' && str.length > 0) return;

  throw new Error('string is empty or not a expected type.');
}

export function checkNonEmptyObject(obj) {
  if (typeof obj === 'object' && obj !== null && Object.keys(obj).length > 0)
    return;

  throw new Error('object is empty or not a expected type.');
}

export function checkNonEmptyStringArray(array) {
  if (Array.isArray(array) && array.length > 0) {
    array.forEach((item) => {
      if (typeof item !== 'string') {
        throw new Error('this variable  is not a string.');
      }
    });
    return;
  }

  throw new Error('array is empty or not a expected type.');
}

export function checkStringType(str) {
  if (typeof str === 'string') {
    return;
  }

  throw new Error('this variable  is not string type.');
}

export function checkNumberType(num, name) {
  if (typeof num === 'number') {
    return;
  }
  throw new Error(`${name || 'this variable'}  is not number type.`);
}

export function checkBooleanType(bool) {
  if (typeof bool === 'boolean') {
    return;
  }

  throw new Error(
    'this variable  is not bool type. but it is ' +
      typeof bool +
      ' type, value is ' +
      bool +
      '.'
  );
}

export function checkObjectType(obj, name) {
  if (typeof obj === 'object' && obj !== null) {
    return;
  }

  throw new Error(
    `${name || 'this variable'}  is not object type. it is ${obj}`
  );
}

export function checkArrType(arr, name) {
  if (Array.isArray(arr)) {
    return;
  }

  throw new Error(`${name || 'this variable'}  is not array type.`);
}

export default {
  checkNonEmptyArray,
  checkNonEmptyString,
  checkNonEmptyObject,
  checkNonEmptyStringArray,
  checkStringType,
  checkNumberType,
  checkBooleanType,
  checkObjectType,
  checkArrType,
};
