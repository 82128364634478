// Note: This is a helper to get meta tags for each page. Different pages have different meta tags.

// meta is significant for SEO, however, google-bot does not provide user-agent / accept-language in request header.
// so, we need to write different url in sitemap.xml, and then google-bot will crawl the url with different language.
// I need to follow the domains of urls to decide which language of meta tags should be used.

import enMeta from './metas_en.json';
import zhMeta from './metas_zh.json';
/**
 * to detect domain of url, and then return json of meta tags by read json files in current dir.
 * @param {*} url
 * @returns meta json by domain of the url.
 */
export function getMeta4Url(url) {
  let meta = enMeta;
  if (url.includes('/zh')) {
    meta = zhMeta;
  }
  // to parse url, and get domain.
  const urlObj = new URL(url);
  const domain = urlObj.hostname;
  if (domain.includes('cn.') || domain.includes('zh.')) {
    meta = zhMeta;
  }
  return meta;
}

/**
 * return meta directly by language.
 * @param {*} lan : ['zh', 'en']
 * @returns
 */
export function getMetaByLan(lan) {
  let meta = enMeta;

  if (lan === 'zh') {
    meta = zhMeta;
  }
  return meta;
}

export default {
  getMeta4Url,
  getMetaByLan,
};
