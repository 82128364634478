/**
 * to define a react component with bootstrap-react components.
 * The component must have features:
 * 1. render loading with Spinner.
 * 2. render a button to retry.
 * 3. It also need some props to control the loading and retry.
 */

import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const LoadingNRetry = (props) => {
  const { t } = useTranslation();

  const { loading, showingRetry } = props;
  if (!loading && !showingRetry) return null;
  // in this case below, it can not call back click event.
  if (showingRetry && !props.retry) throw new Error('props.retry is null.');

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center text-center"
      style={{ height: 300 }}
    >
      {loading && (
        <Spinner animation="border" variant="primary" className="mb-3" />
      )}
      {!loading && props.showingRetry && (
        <Button variant="primary" onClick={props.retry} className="mb-3">
          {t('重试')}
        </Button>
      )}
      <p className="text-center">{props.message}</p>
    </div>
  );
};

// to define Props
LoadingNRetry.propTypes = {
  message: PropTypes.string,
  retry: PropTypes.func,
  loading: PropTypes.bool,
  showingRetry: PropTypes.bool,
};

export default LoadingNRetry;
