// Note: User Info Helper Utilities
import axios from 'axios';

export function readUser() {
  var storedUserString = localStorage.getItem('user');
  if (!storedUserString) {
    return null;
  }

  // 将字符串转换为JSON对象
  var storedUser = JSON.parse(storedUserString);
  return storedUser;
}

export function writeUser(user) {
  var userString = JSON.stringify(user);

  // 存储在localStorage中
  localStorage.setItem('user', userString);
}

export function writeToken(token) {
  localStorage.setItem('token', token);
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function readToken() {
  return localStorage.getItem('token');
}

export function removeUser() {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

// it is used to detect token is expired or not.
// if token is expired, it will show a dialog to user.
export async function readTokenWithDialog(getAccessTokenSilentlyFunc) {
  try {
    const token = await getAccessTokenSilentlyFunc();
    return token;
  } catch (e) {
    console.error(e);

    const showModal = window.showModal;
    showModal('token失效', '请点击屏幕右上角登出，再重新登陆', 'error');
    localStorage.removeItem('token');
    return '';
  }
}
// const readToken = async (getAccessTokenSilentlyFunc) => {
//   try {
//     const token = await getAccessTokenSilentlyFunc();
//     return token;
//   }
//   catch (e) {
//     console.error(e);

//     const showModal = window.showModal;
//     showModal('token失效', '请点击屏幕右上角登出，再重新登陆');
//     return '';
//   }
// };

export default {
  readToken,
  readTokenWithDialog,
  readUser,
  writeUser,
  removeUser,
  writeToken,
};
