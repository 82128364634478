/* eslint-disable no-unused-vars */
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
// import i18n
import { useTranslation } from 'react-i18next';

function MsgModal(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    props.onClose();
    setShow(false);
  };

  const { title, msg, btnVariant } = props;

  return (
    <Modal centered show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>{msg}</div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant={btnVariant || 'primary'}
          onClick={() => {
            setShow(false);
            // eslint-disable-next-line react/prop-types
            props.onClose();
          }}
        >
          {t('OK')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

MsgModal.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  btnVariant: PropTypes.string,
  onClose: PropTypes.func,
};

export default MsgModal;
