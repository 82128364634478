import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// meta tags are used for SEO. Normal users doesn't need to care about that.
const CustomHelmet = ({ currentMeta }) => {
  return (
    <Helmet>
      {/** to map meta tags from currentMeta  **/}
      <title>{currentMeta.title}</title>

      <meta name="title" content={currentMeta.title} data-react-helmet="true" />
      <meta
        data-react-helmet="true"
        name="keywords"
        content={currentMeta.keywords}
      />
      <meta
        data-react-helmet="true"
        name="description"
        content={currentMeta.description}
      />
    </Helmet>
  );
};

CustomHelmet.propTypes = {
  currentMeta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    keywords: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default CustomHelmet;
