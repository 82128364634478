import LimitedMap from './LimitedMap';

import BACKEND_URLS from '../URLPaths';

let selectedVoice;

const TTSSERVER = BACKEND_URLS.TTS;
export function speechByLocal(text) {
  if (!selectedVoice) {
    const voices = speechSynthesis.getVoices();
    console.log('voices', voices);

    let voiceToSelect;
    voiceToSelect = voices.find((voice) => voice.name === 'Agnes');
    voiceToSelect =
      voiceToSelect || voices.find((voice) => voice.name === 'Shelley');

    selectedVoice = voiceToSelect;
  }

  const utterance = new SpeechSynthesisUtterance(text);
  utterance.volume = 1.0;

  // 设置语言
  if (selectedVoice) {
    utterance.voice = selectedVoice;
  } else {
    utterance.lang = 'en-GB';
  }
  // utterance.pitch = 0.2;
  // // to set rate by random
  // utterance.rate = 0.9;

  speechSynthesis.speak(utterance);
  // console.log('selectedVoice', selectedVoice.name);
}

const audiosMap = new LimitedMap(20);
export function speech(text) {
  if (!TTSSERVER) {
    console.error('TTSSERVER is not set');
    return;
  }

  if (!text) return;
  // TODO voice can be diversified

  let serverTTSAudio =
    audiosMap.get(text) || new Audio(TTSSERVER + `?text=${text}&voice=0`);
  audiosMap.set(text, serverTTSAudio);
  serverTTSAudio.play();
}

export function fetchTextAudio(text) {
  if (!text) {
    console.error('text is empty');
    return;
  }
  if (!TTSSERVER) {
    console.error('TTSSERVER is not set');
    return;
  }

  if (audiosMap.get(text)) return;

  let serverTTSAudio = new Audio(TTSSERVER + '/?text=' + text);
  audiosMap.set(text, serverTTSAudio);
  serverTTSAudio.load();
  console.log('fetchTextAudio word: ', text);
}

export default {
  speech,
  speechByLocal,
  fetchTextAudio,
};
