import { checkStringType } from './FrontendTypeCheckers';

/**
 *
 *  * to explain what rule this function to separate.
 * e.g. input: '4. All students must take this ___ to determine their grade in the course.'
 * to separate to ['4. All students must take ',
 *                  'this',
 *                  ' ___',
 *                  ' to',
 *                  ' determine their grade in the course.'
 *  ]
 *
 * @param {*} inputsentence
 * @param {*} inputkeyword
 * @returns return undfined or [keywordIndex, spaceIndexBefore, spaceIndexAfter].
 */
export function findHighlightIndexes(inputsentence, inputkeyword) {
  if (!inputkeyword) throw new Error('inputkeyword is required');
  // if (inputkeyword !== '___') throw new Error('inputkeyword must be ___, but it is ' + inputkeyword);

  if (!inputsentence) {
    return undefined;
  }
  checkStringType(inputsentence);
  checkStringType(inputkeyword);

  const sentence = inputsentence.toLowerCase();
  const keyword = inputkeyword.toLowerCase();

  const keywordIndex = sentence.indexOf(keyword);

  if (keywordIndex === -1) {
    return undefined;
  }

  // before
  let spaceCount = 0;
  let spaceIndexBefore = -1;
  if (keywordIndex > 2) {
    for (let i = keywordIndex - 1; i >= 0; i--) {
      if (sentence[i] === ' ') {
        spaceCount++;

        if (spaceCount === 2) {
          spaceIndexBefore = i;
          break;
        }
      }
    }
  }

  // afterwards
  spaceCount = 0;
  let spaceIndexAfter = -1;
  for (let i = keywordIndex + keyword.length; i < sentence.length; i++) {
    if (sentence[i] === ' ') {
      spaceCount++;

      if (spaceCount === 2) {
        spaceIndexAfter = i;
        break;
      }
    }
  }

  return [keywordIndex, spaceIndexBefore, spaceIndexAfter];
}

export default { findHighlightIndexes };
