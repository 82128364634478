const WS_V = 'v1';
const HTTP_V = 'v1';
const VERSION_PREFIX = `/api/${HTTP_V}`;

const BACKEND_URLS = {
  TOKEN: `${VERSION_PREFIX}/token`,
  SET_CREATION_PATH: `${VERSION_PREFIX}/create_studyset`,
  MY_STUDYSET_PATH: `${VERSION_PREFIX}/my_vocab_sets`,
  MY_PHRASE_SETS_PATH: `${VERSION_PREFIX}/my_phrase_sets`,
  MY_STUDYSET_TDETAIL_PATH: `${VERSION_PREFIX}/studyset_detail`,
  WORD_SENTENCES_PATH: `${VERSION_PREFIX}/word/sentences`,
  PHRASE_SENTENCES_PATH: `${VERSION_PREFIX}/phrase/sentences`,
  IMPORT_WORDS_BY_INPUTBOX: `${VERSION_PREFIX}/import_words`,
  IMPORT_PHRASES_BY_INPUTBOX: `${VERSION_PREFIX}/import_phrases`,
  STAR_SENTENCE: `${VERSION_PREFIX}/star_sentence`,
  DEL_SENTENCE: `${VERSION_PREFIX}/del_sentence`,
  LEARN_SENTENCE: `${VERSION_PREFIX}/learn_sentence`,
  MASTER_SENTENCE: `${VERSION_PREFIX}/master_sentence`,
  LEARN_SENTENCES: `${VERSION_PREFIX}/learn_sentences`,
  LEARNING_MATERIALS: `${VERSION_PREFIX}/learning_materials`,
  STUDYSET_INFO: `${VERSION_PREFIX}/studyset_info`,
  STUDYSET_DELETE: `${VERSION_PREFIX}/delete_studyset`,
  STUDYSET_UPDATE: `${VERSION_PREFIX}/studyset_update`,
  CHECK_WORDS: `${VERSION_PREFIX}/words_check`,
  PHRASES_CHECK: `${VERSION_PREFIX}/phrases_check`,
  LISTEN_MATERIALS: `${VERSION_PREFIX}/listening_materials`,
  OCR_UPLOAD: `/ocr/upload`,
  OCR_RESULT: `/ocr/upload_result`,
  MY_PHRASE_LIST_PATH: `${VERSION_PREFIX}/my_phrase_sets`,
  TRANSLATION: `${VERSION_PREFIX}/translation`,
  WORD_DEFINITION: `${VERSION_PREFIX}/word/en_definition`,
  RELATED_WORDS: `${VERSION_PREFIX}/related_words`,
  WORDLIBS: `${VERSION_PREFIX}/wordlibs`,
  PHRASAL_LIBS: `${VERSION_PREFIX}/phrasal_libs`,
  IMPORT_WORDS_VIA_LIB: `${VERSION_PREFIX}/import_words_via_lib`,
  IMPORT_PHRASES_VIA_LIB: `${VERSION_PREFIX}/import_phrases_via_lib`,
  CHAT_QUESTION_LIST: `${VERSION_PREFIX}/chat/questions`,
  WS_CHAT: `/ws/${WS_V}/chat/input`,
  TTS: `${VERSION_PREFIX}/tts`,
};

export default BACKEND_URLS;
