import { checkStringType } from './FrontendTypeCheckers';

const ALLOWED_LANGUAGES = ['zh', 'en'];

// JavaScript function to get non-English language code based on system and browser settings
export function getNonEnglishLanguage() {
  // Get current system and browser language codes
  const currentSysLan = navigator.language || '';
  const currentBrowserLan = navigator.browserLanguage || '';

  // Function to check if a language code is non-English
  const isNonEnglish = (lang) => {
    const iso639Code = lang.split('-')[0]; // Get the language part before the region code
    return iso639Code !== 'en';
  };

  // Extract the ISO 639-1 language code
  const extractLanguageCode = (lang) => lang.split('-')[0];

  // Check and return the non-English system language if available
  if (currentSysLan && isNonEnglish(currentSysLan)) {
    return extractLanguageCode(currentSysLan);
  }

  // Check and return the non-English browser language if available
  if (currentBrowserLan && isNonEnglish(currentBrowserLan)) {
    return extractLanguageCode(currentBrowserLan);
  }

  // Return null if no non-English language found
  return null;
}

// detect Chinese language in Browser environment.
// ['ENBot', 'CNBot'] are used to detect language in https://github.com/ScitudyTeam/StaticMainsite-by-puppeteer.
export function isChineseExistedInBrowser() {
  const currentSysLan = navigator.language || '';
  const currentBrowserLan = navigator.browserLanguage || '';
  const filters = navigator.languages?.filter((lan) => lan.startsWith('zh'));

  const userAgent = navigator.userAgent;

  if (userAgent.includes('ENBot')) {
    return false;
  }

  // 判断当前语言是中文还是英文
  return (
    userAgent.includes('CNBot') ||
    currentSysLan.startsWith('zh') ||
    currentBrowserLan.startsWith('zh') ||
    filters.length > 0
  );
}

export function setLanguage(lan) {
  checkStringType(lan);
  if (!ALLOWED_LANGUAGES.includes(lan)) {
    throw new Error(
      `Language must be in ${JSON.stringify(ALLOWED_LANGUAGES)} but got ${lan}.`
    );
  }

  window.language = lan;
  localStorage.setItem('user_set_language', lan);
}

/**
 *
 * @param {*} url
 * @returns ['zh', 'en'], or undefined.
 */
export function getLanByUrl(url) {
  if (!url) return undefined;
  if (url.includes('/zh')) {
    return 'zh';
  }
  // to parse url, and get domain.
  const urlObj = new URL(url);
  const domain = urlObj.hostname;
  if (domain.includes('cn.') || domain.includes('zh.')) {
    return 'zh';
  }
  if (url.includes('/en')) {
    return 'en';
  }
  if (domain.includes('en.')) {
    return 'en';
  }
  return undefined;
}

let lan;
export function getUserSetLanguage() {
  if (lan) return lan;
  lan = localStorage.getItem('user_set_language');
  return lan;
}

export default {
  isChineseExistedInBrowser,
  setLanguage,
  getLanByUrl,
  getUserSetLanguage,
  getNonEnglishLanguage,
};
