import React from 'react';
import PropTypes from 'prop-types';

import Highlighter from 'react-highlight-words';
import { findHighlightIndexes } from '../utils/HighlightUtils';

const BEFORE_PROPOSITIONS = [
  'in',
  'on',
  'of',
  'at',
  'for',
  'with',
  'from',
  'by',
  'about',
  'as',
  'into',
  'like',
  'through',
  'after',
  'over',
  'between',
  'out',
  'against',
  'during',
  'without',
  'before',
  'under',
  'around',
  'among',
];

const POST_PROPOSITIONS = [
  'ing',
  'in',
  'on',
  'at',
  'to',
  'of',
  'for',
  'with',
  'from',
  'by',
  'about',
  'as',
  'into',
  'like',
  'through',
  'after',
  'over',
  'between',
  'out',
  'against',
  'during',
  'without',
  'before',
  'under',
  'around',
  'among',
];

// put spaces beside every preposition.
for (let i = 0; i < BEFORE_PROPOSITIONS.length; i++) {
  BEFORE_PROPOSITIONS[i] = ' ' + BEFORE_PROPOSITIONS[i].trim() + ' ';
}

for (let i = 0; i < POST_PROPOSITIONS.length; i++) {
  POST_PROPOSITIONS[i] = ' ' + POST_PROPOSITIONS[i].trim() + ' ';
}

// some word end with "ing"
const WORDS_AVOIDED = ['something', 'anything', 'nothing', 'everything'];

/**
 * some instructions:
 * 1. define className:
 *   <Highlighter highlightClassName="YourHighlightClass"
 * 2.  caseSensitive, this is a option to set boolean value, default is false.
 */
function SentenceHighlighter(props) {
  const { sentence, word } = props;

  const highlightCSS = {
    backgroundColor: 'gray',
    fontWeight: 'bold',
  };

  let keyword = word;
  if (sentence.includes('_')) {
    const regexOfUnderline = /_+/g;
    // to find how many underline char in sentence
    const match = sentence.match(regexOfUnderline);
    if (match && match.length > 0) {
      keyword = match[0];
    }
  }

  const indexes = findHighlightIndexes(sentence, keyword);

  if (!indexes || indexes.length !== 3) {
    console.warn('SentenceHighlighter: indexes is not valid');
    return <p>{sentence}</p>;
  }

  const keywordIndex = indexes[0];
  const spaceIndexBefore = indexes[1];
  const spaceIndexAfter = indexes[2];

  const parts = [];
  if (spaceIndexBefore > -1) {
    parts.push(sentence.substring(0, spaceIndexBefore));
    parts.push(sentence.substring(spaceIndexBefore, keywordIndex));
  } else {
    parts.push('');
    parts.push(sentence.substring(0, keywordIndex));
  }

  parts.push(sentence.substring(keywordIndex, keywordIndex + keyword.length));

  if (spaceIndexAfter > -1) {
    parts.push(
      sentence.substring(keywordIndex + keyword.length, spaceIndexAfter + 1)
    );
    parts.push(sentence.substring(spaceIndexAfter + 1));
  } else {
    parts.push('');
    parts.push(sentence.substring(keywordIndex + keyword.length));
  }

  if (parts.length !== 5) {
    throw new Error('parts.length !== 5');
  }
  return (
    <p className="text-justify" style={{ whiteSpace: 'pre-wrap' }}>
      {parts[0]}
      <Highlighter
        caseSensitive={true}
        highlightStyle={highlightCSS}
        searchWords={BEFORE_PROPOSITIONS}
        textToHighlight={parts[1]}
      />
      {parts[2]}

      {WORDS_AVOIDED.includes(parts[3].trim()) ? (
        parts[3]
      ) : (
        <Highlighter
          highlightStyle={highlightCSS}
          caseSensitive={true}
          searchWords={POST_PROPOSITIONS}
          textToHighlight={parts[3]}
        />
      )}
      {parts[4]}
    </p>
  );
}

SentenceHighlighter.propTypes = {
  sentence: PropTypes.string.isRequired,
  word: PropTypes.string,
};

export default SentenceHighlighter;
