// frontend map with limited size
export class LimitedMap {
  constructor(maxSize) {
    this.maxSize = maxSize;
    this.map = new Map();
  }

  set(key, value) {
    this.map.set(key, value);
    if (this.map.size > this.maxSize) {
      const firstKey = this.map.keys().next().value;
      this.map.delete(firstKey);
    }
  }

  get(key) {
    return this.map.get(key);
  }

  delete(key) {
    this.map.delete(key);
  }

  has(key) {
    return this.map.has(key);
  }

  clear() {
    this.map.clear();
  }

  size() {
    return this.map.size;
  }

  entries() {
    return this.map.entries();
  }
}

export default LimitedMap;
