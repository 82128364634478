import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';

// to import Spinner
import Spinner from 'react-bootstrap/Spinner';
import * as Icon from 'react-bootstrap-icons';

import { Collapse } from 'react-bootstrap';
import { requestHint } from '../utils/HintProcessor';

const Hinter = (props) => {
  // isOpen
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { word, sentenceModel } = props;
  const [hintData, setHintData] = useState(null);

  const requestData = async () => {
    setIsLoading(true);

    const callbackHint = (hintData) => {
      console.log('hintData: ' + JSON.stringify(hintData));
      if (hintData) {
        setHintData(hintData);
      }
      setIsLoading(false);
    };
    requestHint(sentenceModel, word, callbackHint).catch((err) => {
      console.log(err);
      setIsLoading(false);
    });
  };

  return (
    <div
      className="border border-secondary rounded text-secondary px-2"
      onClick={() => {
        const nextState = !isOpen;
        !nextState && setHintData(null);
        nextState && requestData();
        setIsOpen(nextState);
      }}
    >
      <span>
        <Icon.ExclamationDiamondFill color="yellow" />{' '}
        {hintData ? hintData.hintType + ': ' : 'hint'}
      </span>

      <Collapse in={isOpen}>
        <div className="mt-3">
          {isLoading == true ? (
            <span>
              <Spinner animation="grow" size="sm" /> loading...
            </span>
          ) : null}

          {!isLoading && hintData ? (
            <p className="font-italic">
              {!hintData.data ? (
                <span className="text-warning"> Error</span>
              ) : null}
              {hintData.data && hintData.data.length == 0 ? (
                <span className="text-warning"> 暂无数据</span>
              ) : null}
              {hintData.data &&
                hintData.data.map((item, index) => {
                  return (
                    <span
                      className="border border-info px-2"
                      key={hintData.hintType + index}
                    >
                      {item}
                    </span>
                  );
                })}
            </p>
          ) : null}
        </div>
      </Collapse>
    </div>
  );
};

Hinter.propTypes = {
  sentenceModel: PropTypes.object.isRequired,
  word: PropTypes.string,
};

export default Hinter;
