/* eslint-disable no-unused-vars */
import axios from 'axios';
import { readToken } from './UserInfoHelper';

import i18n from '../../i18n';
// ES6
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import { checkStringType } from '../utils/FrontendTypeCheckers';

// export this variable to tell up level logics that Error Modal is showing, so that the logic can avoid to show it repeatedly.
export let showingResponseError = false;

axiosRetry(axios, {
  retries: 0,
  shouldResetTimeout: true,
  retryCondition: (error) =>
    isNetworkOrIdempotentRequestError(error) || error.code === 'ECONNABORTED',
});
// Custom retry delay
axiosRetry(axios, {
  retryDelay: (retryCount) => {
    return 1500;
  },
});

//这个工作于前端页面重新刷新 或者初始化。
axios.defaults.headers.common['Authorization'] = `Bearer ${readToken()}`;
axios.interceptors.request.use((config) => {
  if (!config.timeout) {
    config.timeout = 5000; // Wait for 4 seconds before timing out
  }
  return config;
});

axios.interceptors.response.use((response) => {
  // response.data.httpCode = response.status;
  // 对于HTTP状态码为200的响应，直接返回响应数据
  if (response.status === 200) {
    return response.data;
  } else {
    return response.data;
  }
});

function mergeParams(api, paramsInput) {
  const params = new URLSearchParams(paramsInput);
  return `${api}${api.includes('?') ? '&' : '?'}${params}`;
}

function checkParams(url, paramsInput) {
  // if url is not a string or empty string
  if (typeof url !== 'string' || url.length === 0) {
    throw new Error('url must be a string.');
  }
}

function createHeaders(token, timeout) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout,
  };
}

/**
 * @throws {Error} - if the api is not a string.
 * @param api
 *
 * @returns {Promise<AxiosResponse>}
 */
export async function get(api) {
  checkStringType(api);
  try {
    const response = await axios.get(api);
    return response;
  } catch (error) {
    console.error(error);
    if ('ECONNABORTED' == error.code) {
      throw error;
    } else if (error.response) {
      preHandleRspError(error);
      throw new Error(error.response.data['message']);
    } else {
      throw new Error('Request failed.');
    }
  }
}

/**
 *
 * @param api
 * @param token
 * @param params: like { name: 'John Doe', age: 30 }.
 *
 * @returns {Promise<AxiosResponse>}
 */
export async function getWithParams(api, token, paramsInput, timeout) {
  checkParams(api, paramsInput);
  try {
    const response = await axios.get(
      mergeParams(api, paramsInput),
      createHeaders(token, timeout)
    );
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error(error);
      if ('ECONNABORTED' == error.code) {
        throw error;
      } else if (error.response) {
        preHandleRspError(error);
        throw new Error(error.response.data['message']);
      } else {
        throw new Error('Request failed.');
      }
    }
  }
}

/**
 *
 * @param api
 * @param token
 * @param params: like { name: 'John Doe', age: 30 }.
 *
 * @returns {Promise<void>}
 */
export async function post(api, paramsInput) {
  checkParams(api, paramsInput);

  try {
    const response = await axios.post(api, paramsInput);
    return response;
  } catch (error) {
    if (!error) return;
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error(error);
      preHandleRspError(error);
      throw error;
    }
  }
}

/**
 *
 * @param {*} api
 * @param {*} paramsInput
 * @param {number} timeout - its unit is millisecond.
 * @returns
 */
export async function postWithTimeout(api, paramsInput, timeout) {
  checkParams(api, paramsInput);
  const instance = axios.create({
    timeout: timeout,
  });

  axiosRetry(instance, {
    retries: 0,
  });

  try {
    const response = await instance.post(api, paramsInput);
    return response;
  } catch (error) {
    console.error(error);
    preHandleRspError(error);
    throw error;
  }
}

/**
 *
 * @param api
 * @param token
 * @param params: like { name: 'John Doe', age: 30 }.
 *
 * @returns {Promise<void>}
 */
export async function postWithoutAuth(api, paramsInput) {
  checkParams(api, paramsInput);

  try {
    // 1st: url , 2nd: body, 3rd: headers
    const response = await axios.post(api, paramsInput);
    return response;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled:', error.message);
    } else {
      console.error(error);
      preHandleRspError(error);
      throw new Error('Request failed.');
    }
  }
}

// to preprocess httpCode [429], [500], [502], [503], [504]
function preHandleRspError(error) {
  const httpCode = error.response?.status;
  const statusText = error.response?.statusText;
  let errTip;
  let isAuthErr = false;
  if (httpCode === 429) {
    errTip = i18n.t('您的操作过于频繁，请稍后再试');
  } else if (statusText == 'Unauthorized' || httpCode == 401) {
    // 401 是auth0 middleware 抛的
    errTip = i18n.t('请点击右上角我的，重新登录');
    isAuthErr = true;
  } else if (error.message && error.message.includes('timeout')) {
    errTip = i18n.t('网络超时');
  } else if (error.code == 'ECONNABORTED') {
    errTip = i18n.t('网络超时');
  } else {
    console.error('httpCode: ', httpCode);
    console.error('error.response: ', error.response);
  }
  if (errTip) {
    showingResponseError = true;
    window.showErrorModal(errTip, () => {
      showingResponseError = false;
    });
  }
}

export default { get, postWithoutAuth, post, showingResponseError };
