import { post } from '../apis/requestHelper';

import URLPaths from '../URLPaths';
import { checkObjectType, checkStringType } from './FrontendTypeCheckers';
import LimitedMap from './LimitedMap';
import { readToken } from '../apis/UserInfoHelper';

import WSClient from '../../common/apis/WSClient';

let wsClient = null;
const messages = [];
const onGotWsError = (event) => {
  console.error('WebSocket error:', event.message);
};

let lastHintIndex;

const HINT_TYPES = 4; // picture is currently disable.
const TYPES_DEF = [
  'definition',
  'synonyms',
  'explanation',
  'answer',
  'picture',
]; //TODO picture hint

const definitionCache = new LimitedMap(20);
/**
 *
 * @param {string} word
 * @returns {string[]} - [category, definition]
 */
async function requestEnDef(word) {
  checkStringType(word, 'word');
  if (definitionCache.has(word)) {
    return definitionCache.get(word);
  }
  try {
    const rsp = await post(URLPaths.WORD_DEFINITION, { word: word });
    if (rsp.code == 0 && !rsp.data.err) {
      const result = [rsp.data.category + '.', rsp.data.definition];
      definitionCache.set(word, result);
      return result;
    }
  } catch (err) {
    console.log(err);
  }
  return [];
}

/**
 * @param { object} sentenceModel - e.g. {
                    "text": "2. He ___ his chair closer to the table.",
                    "cnMeaning": "移动",
                    "POS": "v.",
                    "enMeaning": "move"
                },
 * @param {string} word - string type.
 * @param {function} callback
 * 
 * @returns  { object}: e.g.
 * {
 *  hintType: 'definition', 'synonyms', 'picture'
 *  data: []
 * }
 * 
 * hintType in returns is used to render text, pls keep its value simple and user-friendly.
 * 
 */
export async function requestHint(sentenceModel, word, callback) {
  if (lastHintIndex === undefined) lastHintIndex = -1;
  lastHintIndex++;

  checkObjectType(sentenceModel, 'sentenceModel', 'object');

  console.log('requestHint', JSON.stringify(sentenceModel), word);

  const onGotMsgFromWS = (rspstr) => {
    const { isError, text, stream } = JSON.parse(rspstr);

    // TODO lastMsg.isLoading was not tested.
    if (!isError) {
      if (messages.length == 0) messages[0] = '';

      if (stream && text) {
        const lines = text.split('\n');

        messages[messages.length - 1] =
          messages[messages.length - 1] + lines[0];
        if (lines.length > 1) {
          messages.push(...lines.slice(1));
        }
      } else {
        console.log('onGotMsgFromWs', 'Done########');
        callback({
          hintType,
          data: messages,
        });
      }
    } else {
      messages.push('AI error for now.');
      callback({
        hintType,
        data: messages,
      });
    }
  };

  let data;
  const hintType = TYPES_DEF[lastHintIndex % HINT_TYPES];
  console.log('requestHint', 'hintType', hintType);
  switch (lastHintIndex % HINT_TYPES) {
    case 0: {
      if (!wsClient) {
        wsClient = new WSClient(URLPaths.WS_CHAT, onGotWsError, onGotMsgFromWS);
      }
      if (window.language == 'zh' && sentenceModel.cnMeaning) {
        data = {
          hintType,
          data: [sentenceModel.POS + ' ' + sentenceModel.cnMeaning],
        };
      } else if (sentenceModel.enMeaning) {
        // request an English definition.
        const tData = await requestEnDef(word);
        if (word && sentenceModel.enMeaning != word) {
          //原enMeaning 是直接通过CnmMeaning翻译过来的。可能不好用。
          data = {
            hintType,
            data: tData || [],
          };
        }
      }
      callback(data);
      break;
    }
    case 1: {
      if (!word) {
        throw new Error('word is required.');
      }
      if (!wsClient.isConnected()) {
        wsClient.connect();
      }

      try {
        const msgObj = {
          inputMsg: word,
          questionId: 1201,
          token: readToken(),
          lan: window.language,
        };
        messages.length = 0;
        setTimeout(
          () => {
            wsClient.sendMessage(msgObj);
          },
          wsClient.isConnected() ? 50 : 1200
        );
      } catch (err) {
        console.log(err);
        callback(null);
      }
      break;
    }
    case 2: {
      if (word && sentenceModel.enMeaning != word) {
        const tData = await requestEnDef(word);
        callback({
          hintType,
          data: tData || [],
        });
      }
      break;
    }
    case 3: {
      callback({
        hintType,
        data: [word],
      });
    }
  }
  callback(null);
}
