import React from 'react';

import styles from './LineProgressBar.module.css';
import PropTypes from 'prop-types';

const LineProgressBar = (props) => {
  const { progress, total } = props;
  // 这里可以使用其他方法更新进度值
  return (
    <div className={styles.custom_progress + ' progress'}>
      <div
        className={styles.custom_progress + ' progress-bar'}
        role="progressbar"
        style={{
          width: (progress / total) * 100 + '%',
        }}
        aria-valuenow={progress}
        aria-valuemin="0"
        aria-valuemax={total}
      ></div>
    </div>
  );
};

LineProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default LineProgressBar;
